import React from "react"
import styled from "styled-components"

import theme from "@assets/styles/theme"

import HeaderBg from "@assets/job-family-group/banner_job_family_details.jpg"
import BulletGreen from "@assets/styles/bullet-green.svg"

import AppLayout from "@components/layout/App"

const PAGE_HEADER_HEIGHT = "236px"
const PAGE_GREY_HEIGHT = "156px"

const PageHeader = styled.div`
  background-image: url(${HeaderBg});
  background-size: cover;
  background-position: center;
  height: ${PAGE_HEADER_HEIGHT};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const PageHeaderBoxes = styled.div`
  display: flex;
  flex: 1;
  align-items: flex;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 50;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderGrey = styled.div`
  background-color: #fff;
  opacity: 80%;
  color: ${theme.colors.grey.white};
  flex: 1;
  height: ${PAGE_GREY_HEIGHT};

  overflow: hidden;

  @media (max-width: 1023px) {
    background-color: transparent;
    padding: 0;
  }
`

const PageHeaderBlue = styled.div`
  width: 40%;
  height: 60px;

  @media (max-width: 1023px) {
    display: none;
  }
`

const PageHeaderTextContainer = styled.div`
  color: ${theme.colors.grey.darkGray};
  width: min(1140px, calc(100vw - 32px));
  padding: 24px 0;
  z-index: 2;
  height: ${PAGE_GREY_HEIGHT};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1023px) {
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 0;
    height: auto;
  }
`

const PageHeaderH2 = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 0 8px 0;
  max-width: 50%;
  color: inherit;

  @media (max-width: 1023px) {
    color: white;
    font-size: 18px;
    line-height: 21px;
    margin: 20px 0 4px 0;
    max-width: none;
  }
`

const PageHeaderBody = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
  color: inherit;

  @media (max-width: 1023px) {
    color: white;
    padding-bottom: 16px;
    font-size: 12px;
    line-height: 20px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;

  @media (max-width: 1023px) {
    padding: 24px 16px 32px 16px;
  }
`

const PageGrid = styled.div`
  display: grid;
  width: min(1140px, calc(100vw - 32px));
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "description skills"
    "movement skills";
  column-gap: 36px;

  @media (max-width: 1023px) {
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "description"
      "skills"
      "movement";
  }
`

const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: description;
  padding: 16px 0 24px 0;
`

const SubHeader = styled.p`
  margin: 0 0 16px 0;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  color: ${props => props.theme.colors.grey.darkGray};

  @media (max-width: 1023px) {
    text-align: center;
  }
`

const BulletUl = styled.ul`
  list-style-image: url(${BulletGreen});

  margin: 0;
  padding-inline-start: 16px;
  margin-block-end: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 1023px) {
    padding-inline-start: 6px;
  }

  > li {
    padding-left: 12px;
  }

  > li:not(:last-child) {
    margin-bottom: 1em;
  }
`

const MobileScrollMenu = styled.div`
  background-color: ${theme.colors.grey.greyBackground};
  padding: 10px 0;
  display: none;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  position: sticky;
  top: var(--mobile-menu-height);
  z-index: 2;

  & > *:not(:first-child) {
    margin-left: 24px;
  }

  @media (max-width: 1023px) {
    display: flex;
  }

  & div {
    cursor: pointer;
  }

  & .active {
    font-weight: 700;
  }
`

const DesktopInstructions = styled.div`
  margin-top: 8px;
  color: ${theme.colors.grey.black};
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  @media (max-width: 1023px) {
    display: none;
  }
`
const MobileInstructions = styled.div`
  margin-top: 8px;
  color: ${theme.colors.grey.black};
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  padding: 0 4px;
  text-align: center;
  display: none;
  @media (max-width: 1023px) {
    display: block;
  }
`

const JobPage = props => {
  const { pageContext } = props

  const scrollOffset = 90
  const topOffset = 70

  const sections = [
    {
      name: "Description",
      scrollspy: "description",
    },
  ]

  const [activeSection, setActiveSection] = React.useState("description")

  React.useEffect(() => {
    const handleScroll = () => {
      function isInViewport(el) {
        const { top } = el.getBoundingClientRect()
        const { innerHeight } = window
        return top > topOffset && top < innerHeight
      }

      ;[...sections].reverse().forEach(section => {
        const el = document.querySelector(
          `[data-scroll-spy='${section.scrollspy}']`
        )
        if (isInViewport(el)) {
          setActiveSection(section.scrollspy)
        }
      })
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const handleScrollMenuClick = section => {
    const selector = `[data-scroll-spy="${section}"]`
    const el = document.querySelector(selector)

    if (el) {
      // use scrollTo(left, top) instead of option for IE
      window.scrollTo(0, el.offsetTop - scrollOffset)
    }
  }

  return (
    <AppLayout>
      <PageHeader>
        <PageHeaderBoxes>
          <PageHeaderGrey />
          <PageHeaderBlue />
        </PageHeaderBoxes>
        <PageHeaderTextContainer>
          <PageHeaderH2>{pageContext.name}</PageHeaderH2>
          <PageHeaderBody>
            Job Family Group: {pageContext.jobFamilyGroupName}
          </PageHeaderBody>
        </PageHeaderTextContainer>
      </PageHeader>
      <MobileScrollMenu>
        {sections.map(s => {
          return (
            <div
              className={activeSection === s.scrollspy ? "active" : ""}
              key={`mobile-menu-${s.scrollspy}`}
              onClick={() => handleScrollMenuClick(s.scrollspy)}
            >
              {s.name}
            </div>
          )
        })}
      </MobileScrollMenu>
      <Content>
        <PageGrid>
          <DescriptionSection data-scroll-spy="description">
            <SubHeader>Job Family Description</SubHeader>
            <BulletUl>
              {pageContext.description.map((jd, i) => (
                <li key={`description-item-${i}`}>{jd}</li>
              ))}
            </BulletUl>
          </DescriptionSection>
        </PageGrid>
      </Content>
    </AppLayout>
  )
}

export default JobPage
